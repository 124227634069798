/* global wc_bookings_booking_form */

/*
 * This script registers functions to wc_bookings_booking_form global object.
 * These functions return product setting value for specific booking form.
 *
 * @since 1.15.73
 */
jQuery( document ).ready( function ( $ ) {
	/**
	 * Should return whether multiple booking forms exist on webpage.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {boolean}
	 */
	wc_bookings_booking_form.is_multiple_booking_forms_exist = () => {
		return !! $( '.wc-bookings-booking-form' ).closest( 'form' ).length;
	};

	/**
	 * Should return booking form product id.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_product_id = ( element ) => {
		let form = element.closest( 'form' );
		form = element instanceof jQuery ? form.get(0) : form;

		return parseInt( form.querySelector( '.wc-booking-product-id' ).value );
	};

	/**
	 * Should return booking duration.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_duration = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.booking_duration;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
		][ 'booking_duration' ];
	};

	/**
	 * Should return booking duration type.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_duration_type = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.booking_duration_type;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
		][ 'duration_type' ];
	};

	/**
	 * Should return booking max duration.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_max_duration = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.booking_max_duration;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'booking_max_duration' ];
	};

	/**
	 * Should return booking max duration.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_min_duration = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.booking_min_duration;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'booking_min_duration' ];
	};

	/**
	 * Should return booking check availability against.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_check_availability_against = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.check_availability_against;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'check_availability_against' ];
	};

	/**
	 * Should return booking default availability.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_default_availability = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.default_availability;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'default_availability' ];
	};

	/**
	 * Should return booking duration unit.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_duration_unit = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.duration_unit;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'duration_unit' ];
	};

	/**
	 * Should return booking resources assignment.
	 *
	 * @since 1.15.73
	 *
	 * @param {object} element
	 * @return {string}
	 */
	wc_bookings_booking_form.get_booking_resources_assignment = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.resources_assignment;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'resources_assignment' ];
	};

	/**
	 * Should return booking resources IDs.
	 *
	 * @since 1.16.02
	 *
	 * @param {object} element
	 * @return {array} The resource IDs.
	 */
	wc_bookings_booking_form.get_booking_resource_ids = ( element ) => {
		let product_id = null;

		if( ! wc_bookings_booking_form.is_multiple_booking_forms_exist() ) {
			return window.wc_bookings_booking_form.resource_ids;
		}

		product_id = wc_bookings_booking_form.get_booking_product_id( element );

		return window[
			`wc_bookings_booking_form_${ product_id }`
			][ 'resource_ids' ];
	};

	/**
	 * should return sanitized text
	 *
	 * @since 1.15.73
	 *
	 * @param {string} text
	 *
	 * @return {string}
	 */
	wc_bookings_booking_form.sanitize_text = ( text ) => {
		const element = document.createElement( 'div' );
		element.innerText = text;

		return element.innerHTML;
	};
} );
